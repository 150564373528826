export const onClientEntry = () => {
  if (typeof window !== "undefined") {
    const link = document.createElement("link");
    link.href =
      "https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap";
    link.rel = "preload";
    link.as = "style";
    document.head.appendChild(link);
  }
};
